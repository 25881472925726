
            @import "@/assets/sass/vars.portal.scss";
          

























































































































































































































































































































































.v-stats-rp {
    flex: 0 0 300px;
}

.fa-supermini {
    font-size: 12px !important;
    color: $gray-600;
}

.extra-filter {
    .form-group {
        margin-bottom: 0px !important;
    }
}
